import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.png';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'rotation'],
    locale: 'ja',
    logoUrl,
    dictionaryOverrides: {
      "made-by-text": "Digital Plan"
    },
    colors: {
      main: '#00ADEE',
      mainText: '#FFFFFF',
    }
  }

  const addCaptionBelowControls = () => {
  const controls = document.querySelector('.widget-tab__panorama-controls');
  if (controls) {
    const caption = document.createElement('div');
    caption.className = 'widget-tab__panorama-caption';
    caption.textContent = '※こちらはAIで生成されたものであり、実際の色や設備・家具配置など異なる場合があります';
    controls.parentNode.insertBefore(caption, controls.nextSibling);
  }
};

const observeWidget = () => {
  const widgetContainer = document.querySelector('#widget');
  if (!widgetContainer) {
    console.error('Контейнер #widget не найден');
    return;
  }

  const observer = new MutationObserver(() => {
    const controls = document.querySelector('.widget-tab__panorama-controls');
    if (controls) {
      addCaptionBelowControls();
      observer.disconnect();
    }
  });

    observer.observe(widgetContainer, { childList: true, subtree: true });
  };

  observeWidget();


  new Widget('#widget', options);
}

createWidget('https://digitalplan.digitalstaging.co.jp/');
